export default function normalizeEdges(data: any) {
  if (!data) return [];

  const { edges } = data;

  if (!edges || !Array.isArray(edges)) {
    return [];
  }

  return edges.map((edge) => edge.node);
}
