import normalizeCaseStudies from "./normalizeCaseStudies";
import normalizeEdges from "./normalizeEdges";

export default function normalizeCaseStudiesPage(data: any) {
  const {
    strapiCaseStudiesPage,
    allStrapiCasestudies,
    allStrapiCompanies,
  } = data;

  const undormattedCaseStudies = normalizeEdges(allStrapiCasestudies);
  const allCaseStudies = normalizeCaseStudies(undormattedCaseStudies);
  const unformattedCompanies = normalizeEdges(allStrapiCompanies);

  const companies = (unformattedCompanies || []).map((item) => ({
    name: item.name,
    caseStudies: (item.caseStudies || []).map((item: any) => item.id),
  }));

  const {
    caseStudies: unformattedCaseStudiesList,
    heading,
    subheading,
    text,
    ctaImage,
    ctaImageDesktop,
    ctaText,
    seo,
  } = strapiCaseStudiesPage;

  const caseStudiesList = (unformattedCaseStudiesList || []).filter(
    (item: any) => item.slug
  );

  const caseStudies = caseStudiesList.map((item: any) => {
    const { slug } = item;

    return allCaseStudies.filter((caseStudy) => caseStudy.slug === slug)[0];
  });

  const caseStudiesWithCompany = caseStudies.map((caseStudy: any) => {
    const { strapiId } = caseStudy;

    const company = companies.filter((company) =>
      company.caseStudies.some((caseStudyId: any) => caseStudyId === strapiId)
    )[0];

    return {
      ...caseStudy,
      companyName: company ? company.name : "",
    };
  });

  return {
    caseStudies: caseStudiesWithCompany,
    heading,
    subheading,
    text,
    ctaImage,
    ctaImageDesktop,
    ctaText,
    seo: seo || {},
  };
}
