import React from "react";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";

interface Props {
  image: any | any[];
  eager?: boolean;
}

const StrapiImage: React.FC<Props> = ({ image: rawImage, eager }) => {
  const images = Array.isArray(rawImage) ? rawImage : [rawImage];

  if (!images.every((image) => image && image.localFile)) return null;

  const sources = images.map((image) => getImage(image.localFile));

  const { alternativeText } = images[0];

  if (sources[0]) {
    const other = sources[1]
      ? [
          {
            media: "(min-width: 1025px)",
            image: sources[1],
          },
        ]
      : [];

    const gatsbyImage = withArtDirection(sources[0], other);

    return (
      <GatsbyImage
        image={gatsbyImage}
        alt={alternativeText || ""}
        loading={eager ? "eager" : "lazy"}
      />
    );
  }

  if (images[0]?.localFile?.publicURL) {
    return (
      <picture>
        {images[1]?.localFile?.publicURL && (
          <source
            srcSet={images[1]?.localFile?.publicURL}
            media="(min-width: 1025px)"
          />
        )}
        <img
          src={images[0].localFile.publicURL}
          alt={alternativeText || ""}
          style={{ width: "100%" }}
          loading="lazy"
        />
      </picture>
    );
  }

  return null;
};

export default StrapiImage;
