import ICaseStudy from "../interfaces/ICaseStudy";

export default function getCaseStudySchema(caseStudy: ICaseStudy): any {
  const {
    heading,
    seo,
    leadQuote,
    opengraphImage,
    slug,
    createdAt,
    updatedAt,
  } = caseStudy;

  const { title, description, image } = seo;

  const seoImagePath =
    image?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src ||
    null;

  const fallbackImagePath =
    opengraphImage?.localFile?.childImageSharp?.gatsbyImageData?.images
      ?.fallback?.src;

  const imagePath = seoImagePath || fallbackImagePath || null;

  const imageUrl = imagePath
    ? `https://${process.env.GATSBY_PUBLIC_DOMAIN}${imagePath}`
    : "";

  const publicUrl = `https://${process.env.GATSBY_PUBLIC_DOMAIN}/success-story/${slug}/`;

  return {
    "@context": "https://schema.org",
    "@type": "Article",
    headline: title || heading,
    image: imageUrl,
    publisher: {
      "@type": "Organization",
      name: "Flatworld",
    },
    url: publicUrl,
    datePublished: createdAt,
    dateCreated: createdAt,
    dateModified: updatedAt,
    description: description || leadQuote || "",
  };
}
